import React, { useMemo } from 'react';
import { returnImage, returnName } from '.';
import { Avatar, Box, Flex, HStack, Image } from '@chakra-ui/react';
import { MatchInterface } from '../../../utils/types';

interface OlympicTeam {
  [key: string]: {
    pts: number;
    team: {
      id: string;
      name: string;
      teamLogo: {
        url: string;
      } | null;
    };
  };
}

const OverAll = ({ matches }: { matches: MatchInterface[] }) => {
  const olympicsPoints = useMemo(() => {
    return matches?.reduce((acc: OlympicTeam, match: MatchInterface) => {
      const { homeTeam, awayTeam, stage } = match;

      const homeTeamName = homeTeam.teams?.[0]?.name;
      const awayTeamName = awayTeam.teams?.[0]?.name;

      // Initialize team points if they don't exist
      if (!acc[homeTeamName]) {
        acc[homeTeamName] = {
          pts: 0,
          team: homeTeam?.teams?.[0],
        };
      }
      if (!acc[awayTeamName]) {
        acc[awayTeamName] = {
          pts: 0,
          team: awayTeam?.teams?.[0],
        };
      }

      // Point calculation based on stage and match result
      if (stage === 'Final') {
        // Winner gets 20 points, runner-up gets 12 points
        if (match.homeTeamScore > match.awayTeamScore) {
          acc[homeTeamName].pts += (match.homeTeamExtraPoints || 0) + 20;
          acc[awayTeamName].pts += (match.awayTeamExtraPoints || 0) + 12;
        } else {
          acc[homeTeamName].pts += (match.homeTeamExtraPoints || 0) + 12;
          acc[awayTeamName].pts += (match.awayTeamExtraPoints || 0) + 20;
        }
      } else if (stage === 'Third-place play-off') {
        // Third place gets 7 points, fourth place gets 1 point
        if (match.homeTeamScore > match.awayTeamScore) {
          acc[homeTeamName].pts += (match.homeTeamExtraPoints || 0) + 7;
          acc[awayTeamName].pts += match.awayTeamExtraPoints || 0;
        } else {
          acc[homeTeamName].pts += match.homeTeamExtraPoints || 0;
          acc[awayTeamName].pts += (match.awayTeamExtraPoints || 0) + 7;
        }
      }

      return acc;
    }, {});
  }, [matches]);

  const returnSortedPoints = (data: OlympicTeam) => {
    return Object.keys(data)
      .sort((a, b) => data[b].pts - data[a].pts)
      .reduce((acc: OlympicTeam, key: string) => {
        acc[key] = data[key];
        return acc;
      }, {});
  };

  return (
    <Box>
      <Box p="5px 5px 5px 10px" fontSize="14px" mb="10px" background="gray.100">
        <Flex justifyContent="space-between">
          <Box fontWeight={'bold'}>{'Team'}</Box>
          <Box textAlign={'center'} fontWeight={'bold'}>
            Overall PTS
          </Box>
        </Flex>
      </Box>
      {Object.keys(returnSortedPoints(olympicsPoints)).map((key, index) => {
        const team = olympicsPoints[key].team;
        const pts = olympicsPoints[key].pts;
        // const teamPts =
        //   index === 0 ? 20 : index === 1 ? 12 : index === 2 ? 7 : pts;
        return (
          <HStack
            key={team.id}
            alignItems="center"
            justifyContent="space-between"
            cursor="pointer"
            _hover={{ bg: 'teal.50', color: 'inherit' }}
          >
            <HStack alignItems="center">
              <Flex
                h={'58px'}
                w={'58px'}
                justifyContent="center"
                alignItems="center"
                fontSize="12px"
                textAlign="center"
              >
                <Box>
                  {returnImage(team?.teamLogo?.url) ? (
                    <Image
                      src={returnImage(team?.teamLogo?.url)}
                      boxSize={'50px'}
                      objectFit="cover"
                      m="0 auto"
                      cursor="pointer"
                    />
                  ) : (
                    <Avatar
                      size="sm"
                      name={`${returnName(team.name)} `}
                      src={''}
                    />
                  )}
                </Box>
              </Flex>

              <Flex flexDirection="column" h="58px" justifyContent="center">
                <Flex
                  noOfLines={[1, 1, 2, 2]}
                  fontSize={'14px'}
                  fontWeight={'bold'}
                  alignItems="center"
                  textTransform={'capitalize'}
                  maxW={['83px', '83px', 'auto', 'auto']}
                >
                  {returnName(team.name)}
                </Flex>
              </Flex>
            </HStack>
            <Box px="5px">
              <Box w="30px" textAlign={'center'} fontWeight={'bold'}>
                {pts}
              </Box>
            </Box>
          </HStack>
        );
      })}
    </Box>
  );
};

export default OverAll;
