import {
  Box,
  Button,
  Center,
  FormLabel,
  HStack,
  Image,
  Select,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import BlueIcon from '../../assets/theme-icon.png';
import { useStoreState, useStoreActions } from '../../store';
import { greenishColor } from '../../utils/theme';
import Footer from '../Footer';
import Sidebar from '../Navigation';
import Matches from './Matches';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import usePlayerInfo from '../../hooks/usePlayerInfo';
import useLeaguesState from '../../hooks/useLeaguesState';
import LeagueTable from './LeagueTable';
import TopPlayers from './TopPlayers';
import { MatchInterface } from '../../utils/types';
import KnockoutStage from './KnockoutStage';
// import Team from './Team';
import {
  lowerCaseNoSpaces,
  mapKeyToName,
  upperCaseFirstLetterWithSpaces,
} from '../../utils/helper';
import { MAIN_LEAGUE, SERVER_URL } from '../../utils/constants';
import useLeagueTables, { tableToGroups } from '../../hooks/useLeagueTables';
import Olympics from './Olympics';
import Loader from '../Loader';
import useMatches from '../../hooks/useMatches';

const League = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const { comp } = params;
  const navigate = useNavigate();
  const [_ref, setRef] = useState<(SwipeableViews & HTMLDivElement) | null>(
    null
  );
  const playerInfo = usePlayerInfo();
  const { userModel: userState } = useStoreState((state) => state);
  const [selectedCompetition, setSelectedCompetition] = useState(MAIN_LEAGUE);
  const { isDark } = userState;
  const { userModel: userActions } = useStoreActions((state) => state);
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedType, setSelectedType] = useState('Football');

  const handleChangeIndex = (index: number) => setTabIndex(index);
  const { competitionsList, publicCompetitions } = useLeaguesState();
  const currentLeague = useMemo(() => {
    return competitionsList?.find((x: any) => x.name === selectedCompetition);
  }, [competitionsList, selectedCompetition]);

  const [selectedSeason, setSelectedSeason] = useState('');

  const seasonId = useMemo(() => {
    if (selectedSeason) {
      const foundSeason = currentLeague?.seasons?.find(
        (season) => season?.name === selectedSeason
      );
      if (foundSeason) return foundSeason?.id;
    }
    if (searchParams.get('season') && currentLeague) {
      const foundSeason = currentLeague?.seasons?.find(
        (season) =>
          season?.name ===
          upperCaseFirstLetterWithSpaces(searchParams.get('season') ?? '')
      );
      if (foundSeason) return foundSeason?.id;
    }
    return currentLeague?.currentSeason?.id;
  }, [currentLeague, selectedSeason, searchParams]);

  // const { data: matchesData, loading: matchLoading } = useQuery(
  //   FETCH_LEAGUE_MATCHES,
  //   {
  //     variables: {
  //       id: currentLeague?.id,
  //       seasonId: seasonId,
  //     },
  //     pollInterval: 5000,
  //   }
  // );
  // const startDate =
  //   searchParams.get('startDate') &&
  //   dayjs(searchParams.get('startDate')).isValid()
  //     ? dayjs(searchParams.get('startDate')).toISOString()
  //     : undefined;
  // const endDate =
  //   searchParams.get('endDate') && dayjs(searchParams.get('endDate')).isValid()
  //     ? dayjs(searchParams.get('endDate')).toISOString()
  //     : undefined;

  const { matches, loading: matchLoading } = useMatches({
    where: {
      season: { id: { in: [seasonId as string] } },
      competition: { id: { in: [currentLeague?.id as string] } },
    },
    pollInterval: 5000,
  });

  const { leagueTables, loading } = useLeagueTables({
    competition: { id: { equals: currentLeague?.id } },
    season: { id: { equals: seasonId } },
  });

  useEffect(() => {
    if (comp) {
      setSelectedCompetition(upperCaseFirstLetterWithSpaces(comp));
    } else {
      navigate(`/league/${lowerCaseNoSpaces(MAIN_LEAGUE)}`);
    }
  }, [comp, navigate]);

  useEffect(() => {
    if (isDark) {
      userActions.setIsDark(false);
    }
  }, [isDark, userActions]);

  useEffect(() => {
    if (searchParams.get('tab') === 'stats') {
      setTabIndex(3);
    }
    if (searchParams.get('season')) {
      setSelectedSeason(
        upperCaseFirstLetterWithSpaces(searchParams.get('season') as string) ??
          ''
      );
    }

    if (searchParams.get('type')) {
      setSelectedType(searchParams.get('type') as string);
    }
    if (!searchParams.get('type') && comp === 'dell') {
      setSelectedType('Volley');
    }
  }, [searchParams, comp]);

  // hacky way to adjust height

  const adjustHeight = () => {
    if (_ref) {
      setTimeout(() => {
        // @ts-ignore
        _ref.updateHeight();
      }, 100);
    }
  };

  useEffect(() => {
    const teamContainer = document.getElementById('team-container');
    if (teamContainer && matches) {
      teamContainer.style.height = `${matches.length * 58}px`;
    }
    const containers = document.getElementsByClassName(
      'react-swipeable-view-container'
    );
    if (containers.length && _ref) {
      // @ts-ignore
      for (const child of containers[0].children) {
        if (child.getAttribute('aria-hidden') === 'false') {
          setTimeout(() => {
            const size = child.childNodes[0].children.length;
            const height = size * 58;
            console.log(height);
            // @ts-ignore
            _ref.updateHeight();
          }, 50);
        }
      }
    }
  }, [tabIndex, _ref, matches]);

  const onRefChange = useCallback((node: SwipeableViews & HTMLDivElement) => {
    // hacky solution to update the height after the first render. Height is not set correctly on initial render
    setRef(node); // e.g. change ref state to trigger re-render
  }, []);
  const groups = useMemo(() => {
    return tableToGroups(leagueTables?.[0]?.table);
  }, [leagueTables]);

  const stats = useMemo(() => {
    const obj = {
      topScorer: leagueTables?.[0]?.topScorer?.length
        ? leagueTables?.[0]?.topScorer
        : [],
      topAssist: leagueTables?.[0]?.topAssist?.length
        ? leagueTables?.[0]?.topAssist
        : [],
      topGoalie: leagueTables?.[0]?.topGoalie?.length
        ? leagueTables?.[0]?.topGoalie
        : [],
      topCard: leagueTables?.[0]?.topCard?.length
        ? leagueTables?.[0]?.topCard
        : [],
    };
    return obj;
  }, [leagueTables]);

  const leagueTypes = useMemo(() => {
    const types = [currentLeague?.mainSportType];
    for (const match of matches || []) {
      if (!types.includes(match.type)) {
        types.push(match.type);
      }
    }
    return types;
  }, [matches, currentLeague?.mainSportType]);

  const selectedCompData = useMemo(() => {
    return matches?.filter((x: MatchInterface) => x.type === selectedType);
  }, [matches, selectedType]);

  return searchParams.get('isWide') === 'true' && !currentLeague?.isPublic ? (
    <Olympics matches={selectedCompData} isWide allMatches={matches} />
  ) : (
    <Sidebar>
      <Stack
        bg="white"
        minH="100vh"
        pt="140px"
        display="flex"
        justifyContent={'space-between'}
      >
        <Box>
          <Box
            padding="35px 0"
            position="relative"
            background={
              currentLeague?.baseColor
                ? currentLeague?.baseColor
                : greenishColor
            }
            dir="ltr"
          >
            <Box p={3} w="100%" maxW={'700px'} m={'auto'} position="relative">
              <Box
                w="70px"
                h="70px"
                borderRadius="50%"
                padding="10px"
                bg={isDark ? 'gray.700' : 'gray.100'}
                position="absolute"
                top="100%"
                left={'0'}
                right={'auto'}
              >
                <Image
                  src={
                    currentLeague?.logo?.url
                      ? SERVER_URL + currentLeague?.logo?.url
                      : BlueIcon
                  }
                  borderRadius="50%"
                  w="50px"
                  h="50px"
                />
              </Box>
            </Box>
          </Box>
          <Box
            p={3}
            w="100%"
            maxW={'700px'}
            m={'auto'}
            position="relative"
            mt={'50px'}
          >
            <HStack>
              <Box w="100%">
                <FormLabel>League</FormLabel>
                <Select
                  mt="4"
                  fontWeight={'bold'}
                  fontSize="lg"
                  value={selectedCompetition}
                  onChange={(e) => {
                    navigate(`/league/${lowerCaseNoSpaces(e.target.value)}`);
                  }}
                >
                  {(currentLeague?.isPublic
                    ? [...(publicCompetitions ?? [])]
                    : [...(competitionsList ?? [])]
                  )?.map((league) => (
                    <option key={league.id} value={league.name}>
                      {mapKeyToName(league.name)}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box w="100%">
                <FormLabel>Season</FormLabel>
                <Select
                  mt="4"
                  fontWeight={'bold'}
                  fontSize="lg"
                  value={selectedSeason || currentLeague?.currentSeason?.name}
                  onChange={(e) => {
                    setSelectedSeason(e.target.value);
                    navigate(
                      `/league/${lowerCaseNoSpaces(
                        selectedCompetition
                      )}?season=${lowerCaseNoSpaces(e.target.value)}`
                    );
                    adjustHeight();
                  }}
                >
                  {currentLeague?.seasons?.map((season) => {
                    return (
                      <option key={season.id} value={season.name}>
                        {season.name}
                      </option>
                    );
                  })}
                </Select>
              </Box>
            </HStack>
            <Box w="100%" mt="20px">
              <FormLabel>Sport Type</FormLabel>
              <Select
                fontWeight={'bold'}
                fontSize="xl"
                value={selectedType}
                onChange={(e) => {
                  setSelectedType(e.target.value);
                }}
              >
                {leagueTypes?.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </Select>{' '}
            </Box>

            <Box mt="20px">
              {leagueTables?.[0]?.table?.length ? (
                <Text fontSize="xl">
                  {'Teams Count'} : {leagueTables?.[0]?.table?.length ?? 0}
                </Text>
              ) : null}

              {playerInfo ? null : (
                <Button
                  mt="10px"
                  bg={currentLeague?.baseColor || greenishColor}
                  color={currentLeague?.textColor || 'white'}
                  _hover={{ bg: currentLeague?.baseColor || greenishColor }}
                  size="sm"
                  onClick={() => navigate('/register')}
                  rounded={'full'}
                  padding="5px 25px"
                >
                  {'Register'}
                </Button>
              )}
            </Box>
            {!currentLeague?.isPublic ? (
              <Olympics matches={selectedCompData} allMatches={matches} />
            ) : (
              <Box>
                <Tabs
                  variant="line"
                  colorScheme="green"
                  onChange={handleChangeIndex}
                  index={tabIndex}
                  mt="50px"
                >
                  <TabList>
                    {/* <Tab
               fontSize={['xs', 'sm', 'md', 'md']}
               padding={['10px', '15px']}
             >
               {'Teams'}
             </Tab> */}
                    <Tab
                      fontSize={['xs', 'sm', 'md', 'md']}
                      padding={['10px', '15px']}
                    >
                      {'Standing'}
                    </Tab>
                    <Tab
                      fontSize={['xs', 'sm', 'md', 'md']}
                      padding={['10px', '15px']}
                    >
                      {'Matches'}
                    </Tab>
                    <Tab
                      fontSize={['xs', 'sm', 'md', 'md']}
                      padding={['10px', '15px']}
                    >
                      {'Knockout Stage'}
                    </Tab>
                    <Tab
                      fontSize={['xs', 'sm', 'md', 'md']}
                      padding={['10px', '15px']}
                    >
                      {'League Stats'}
                    </Tab>
                  </TabList>
                </Tabs>

                <Box>
                  <SwipeableViews
                    index={tabIndex}
                    onChangeIndex={handleChangeIndex}
                    axis={'x'}
                    animateHeight
                    ref={onRefChange}
                  >
                    <Box
                      py={'20px'}
                      h={'fit-content'}
                      minH="200px"
                      id="team-container"
                    >
                      {loading || matchLoading ? (
                        <Center>
                          <Loader />
                        </Center>
                      ) : groups ? (
                        <LeagueTable groups={groups} isFull removeFP />
                      ) : (
                        <Center>Standing data is not available</Center>
                      )}
                    </Box>

                    <Box py={'20px'} h={'fit-content'} minH="200px">
                      {loading || matchLoading ? (
                        <Center>
                          <Loader />
                        </Center>
                      ) : matches?.length ? (
                        <Matches list={matches} />
                      ) : (
                        <Center>No Matches available</Center>
                      )}
                    </Box>
                    <Box py={'20px'} h={'fit-content'} minH="200px">
                      {loading || matchLoading ? (
                        <Center>
                          <Loader />
                        </Center>
                      ) : matches?.filter(
                          (m: MatchInterface) => m.stage !== 'Group Stage'
                        ).length ? (
                        <KnockoutStage
                          matches={matches?.filter(
                            (m: MatchInterface) => m.stage !== 'Group Stage'
                          )}
                        />
                      ) : (
                        <Center>No Matches available</Center>
                      )}
                    </Box>

                    <Box>
                      <Tabs variant="soft-rounded" colorScheme="blue">
                        <TabList mt="30px" justifyContent={'center'}>
                          <Tab>Top Scoreres</Tab>
                          <Tab>Top Assists</Tab>
                          <Tab>Top Goalies</Tab>
                          <Tab>Top Cards</Tab>
                        </TabList>
                        <TabPanels>
                          {Object.keys(stats).map((key: string) => {
                            const skey = key as keyof typeof stats;
                            return (
                              <TabPanel key={key}>
                                <Box py={'20px'} h={'fit-content'} minH="200px">
                                  <TopPlayers
                                    topPlayers={stats[skey]}
                                    type={skey}
                                  />
                                </Box>
                              </TabPanel>
                            );
                          })}
                        </TabPanels>
                      </Tabs>
                    </Box>

                    {/* <Box py={'20px'} h={'fit-content'} minH="200px">
                 {currentLeagueTable ? (
                   <TopPlayers
                     topPlayers={currentLeagueTable?.topScorer}
                     type="Scorers"
                   />
                 ) : (
                   <Center>Top Scoreres are not available</Center>
                 )}
               </Box>

               <Box py={'20px'} h={'fit-content'} minH="200px">
                 {currentLeagueTable ? (
                   <TopPlayers
                     topPlayers={currentLeagueTable?.topAssist}
                     type="Assists"
                   />
                 ) : (
                   <Center>Top Assists are not available</Center>
                 )}
               </Box> */}
                  </SwipeableViews>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Footer />
      </Stack>
    </Sidebar>
  );
};

export default League;
